import { ref, onUnmounted } from 'vue';

export default function useScrollToTop() {
  const isScrollCompleted = ref(false);

  const scrollToTop = (targetElement: Element, callback: object) => {
    isScrollCompleted.value = false;

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        isScrollCompleted.value = true;
        if (callback && typeof callback === 'function') {
          callback();
        }
        observer.disconnect();
      }
    });

    // Postavi observer na zadati element
    observer.observe(targetElement);

    onUnmounted(() => {
      observer.disconnect();
    });
  };

  return {
    scrollToTop,
    isScrollCompleted
  };
}
