<template>
  <TransitionRoot as="template" :show="props.isOpen">
    <Dialog class="relative z-10" @close="handleCloseBar">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-30 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg transition-all w-full"
            >
              <div class="flex h-full flex-col gap-2 overflow-y-scroll bg-neutral_500 p-8">
                <div class="h-10 flex items-center w-full justify-between">
                  <p
                    class="text-[12px] font-bold leading-[150%] tracking-[2.4px] uppercase text-base_white"
                  >
                    {{ $t('followUsers') }}
                  </p>
                  <ClientOnly>
                    <ion-icon
                      @click="handleCloseBar"
                      class="text-[16px] text-base_white cursor-pointer"
                      name="close-outline"
                    ></ion-icon>
                  </ClientOnly>
                </div>
                <div class="h-px w-full bg-primary_800"></div>
                <div
                  v-if="similarUsers.length"
                  class="h-[40px] flex items-center justify-between w-full"
                >
                  <p
                    class="text-[12px] font-bold leading-[150%] tracking-[2.4px] uppercase text-base_white"
                  >
                    {{ $t('likeYou') }}
                  </p>
                  <ButtonComponent
                    :is-loading="isLoadingFollowSimilar"
                    @click="followAllSimilar"
                    :text="$t('followAll')"
                  />
                </div>
                <div
                  v-if="similarUsers.length"
                  class="flex flex-col gap-2 max-h-[245px] overflow-y-scroll"
                >
                  <UserWithFollowButton
                    v-for="user in similarUsers"
                    :user="user"
                    :key="`similar-${user.id}`"
                  />
                </div>
                <div v-if="similarUsers.length" class="h-px w-full bg-primary_800"></div>
                <div class="h-[40px] flex items-center justify-between w-full">
                  <p
                    class="text-[12px] font-bold leading-[150%] tracking-[2.4px] uppercase text-base_white"
                  >
                    {{ $t('interesting') }}
                  </p>
                  <ButtonComponent
                    :is-loading="isLoadingFollowInteresting"
                    @click="followAllInteresting"
                    :text="$t('followAll')"
                  />
                </div>
                <div class="flex flex-col gap-2 max-h-[245px] overflow-y-scroll">
                  <UserWithFollowButton
                    v-for="user in interestingUsers"
                    :user="user"
                    :key="`interesting-${user.id}`"
                  />
                </div>
                <div class="h-px w-full bg-primary_800"></div>
                <ButtonComponent
                  @click="handleCloseBar"
                  :text="$t('closeDialog')"
                  class="mt-2 mx-auto"
                  :cropped="true"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

const emits = defineEmits(['close-bar']);
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  }
});

const { saveValue: hideFollowingSuggestions } = useTmpLocalStorage<boolean>(
  'hideFollowingSuggestions'
);

const {
  similarUsers,
  interestingUsers,
  followAllInteresting,
  followAllSimilar,
  isLoadingFollowInteresting,
  isLoadingFollowSimilar
} = useFollowUsers();

const handleCloseBar = () => {
  hideFollowingSuggestions(true);
  emits('close-bar');
};
</script>
