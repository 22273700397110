<template>
  <div class="w-full max-w-[707px] relative">
    <PinnedPost
      @close-post-for-now="hidePinnedPost = true"
      v-if="pinnedPost && !hidePinnedPost && isContentLoaded"
      :pinned-post="pinnedPost"
    />
    <div ref="divToScrollTo" class="h-[40px] mb-3 flex items-center">
      <p class="text-[12px] font-bold leading-[150%] tracking-[2.4px] uppercase text-base_white">
        {{ $t('home') }}
      </p>
    </div>
    <TabComponent class="mb-6 sm:mb-8 sm:mt-4" :tabs="typeTabs" />
    <ProfessorsChallengeBanner
      v-if="!hideChallengeBanner && isContentLoaded"
      @close-banner-for-now="hideChallengeBanner = true"
    />
    <NuxtLink :to="localePath('/new-post')">
      <FavButton
        v-if="commonStore.isMobile"
        class="fixed z-10 bottom-20 right-4"
        icon="document"
        size="md"
      />
    </NuxtLink>
    <FavButton
      v-if="commonStore.isMobile && newPostsCount > 3"
      class="fixed z-10 bottom-[84px] right-[90px]"
      icon="refresh-circle"
      size="sm"
      @click="reloadPosts"
    />
    <div
      v-if="newPostsCount > 3"
      class="sticky top-10 left-0 right-0 mx-auto w-[200px] flex items-center justify-center z-20"
    >
      <ButtonComponent cropped @click="reloadPosts">
        <ClientOnly>
          <ion-icon class="text-[20px] mr-2" name="reload-outline" />
        </ClientOnly>
        Novi postovi - {{ newPostsCount }}
      </ButtonComponent>
    </div>
    <!-- <MainPageNewPost class="hidden sm:block" ref="divToScrollTo" @post-added="handlePostAdded" /> -->
    <div class="flex flex-col gap-2 sm:gap-4">
      <Lazy
        v-for="(post, index) in posts"
        :key="`lazy-${post.id}`"
        :unrender="true"
        :min-height="determineLazyHeight(post)"
      >
        <Post
          :key="`post-${post.id}`"
          :post="post"
          @post-deleted="handlePostDelete(index)"
          @post-updated="(updatedPost) => handlePostUpdate(updatedPost, index)"
        />
      </Lazy>
    </div>
    <div ref="observerPoint" />
  </div>
  <FollowRecommendationsDrawer
    v-if="!commonStore.isMobile && !hideFollowRecommendations && userId"
    @close-bar="showFollowRecommendationsDrawer = false"
    :is-open="showFollowRecommendationsDrawer && isContentLoaded"
  />
  <FollowRecommendationsDialog
    v-if="commonStore.isMobile && !hideFollowRecommendations && userId"
    @close-bar="showFollowRecommendationsDialog = false"
    :is-open="showFollowRecommendationsDialog && isContentLoaded"
  />
</template>
<script setup lang="ts">
import { getSocket } from '@/socket';
import { postRepository } from '~/repositories/postRepository';
import type { Post, PostReactionSocket, PostResponse, tabItem } from '~/interfaces';
import Lazy from '~/components/Common/Lazy.vue';
import { useDialogStore } from '~/store/dialogStore';
import useScrollToTop from '~/composables/useScrollToTop';
// import MainPageNewPost from '~/components/Post/MainPageNewPost.vue';
import { useUserStore } from '~/store/user';
import { useCommonStore } from '~/store/commonStore';
import TabComponent from '~/components/Common/TabComponent.vue';
import PinnedPost from '~/components/Post/PinnedPost.vue';
// type MainPageNewPostInstance = InstanceType<typeof MainPageNewPost> & {
//   $el: HTMLElement;
// };
type PostType = 'all' | 'following';

const { scrollToTop } = useScrollToTop();
const { value: hidePinnedPostRecord, loadValue: loadHidePinnedPostValue } =
  useTmpLocalStorage<Record<number, boolean>>('hidePinnedPosts');

const { value: storageHideChallengeBanner, loadValue: loadHideChallengeBanner } =
  useTmpLocalStorage<boolean>('hideChallengeBanner');

const { value: storageHideFollowSuggestions, loadValue: loadHideFollowSuggestions } =
  useTmpLocalStorage<boolean>('hideFollowingSuggestions');

const userStore = useUserStore();
const router = useRouter();
const userId = userStore.user?.id;
const commonStore = useCommonStore();
const localePath = useLocalePath();
const logoImage = '/logo.png';

const { $api } = useNuxtApp();
const { t } = useI18n();
const { scrollToSavedPosition } = useScrollToSavedPosition();
const { determineLazyHeight } = useDeterminLazyHeight();
const postRepo = postRepository($api);
const dialogStore = useDialogStore();
const posts = ref<Post[]>([]);
const nextCursor = ref('');
const divToScrollTo = ref<HTMLElement | null>(null);
const route = useRoute();
const newPostsCount = ref(0);
const postsType = ref<PostType>('all');
let numberOfPostServiceCalledTimes = 0;
const pinnedPost = ref<Post | null>(null);
const hidePinnedPost = ref(false);
const hideChallengeBanner = ref(false);
//ubacio sam ovo da ne bi bilo skakanja dok se sve ucita, ako je nesto sakriveno
const isContentLoaded = ref(false);
const showFollowRecommendationsDrawer = ref(true);
const showFollowRecommendationsDialog = ref(true);
const hideFollowRecommendations = ref(false);

const observerPoint = ref(null);
let observer: IntersectionObserver | null = null;

const postsIndexMap = computed(() => {
  const postsMap: Record<string, number> = {};
  for (const [y, post] of posts.value.entries()) {
    postsMap[post.id] = y;
  }
  return postsMap;
});

const typeTabs = computed(
  () =>
    [
      {
        text: `${t('mainWall')}`,
        active: postsType.value == 'all',
        action: () => {
          postsType.value = 'all';
          const newQuery = { ...route.query };
          delete newQuery.type;
          router.push({ query: newQuery });
        }
      },
      {
        text: `${t('following')}`,
        active: postsType.value == 'following',
        action: () => {
          postsType.value = 'following';
          router.push({ query: { ...route.query, type: 'following' } });
        },
        isHidden: !userStore.user?.id
      }
    ] as tabItem[]
);

const serviceByPost: Record<PostType, (nextCursor: string) => Promise<PostResponse>> = {
  all: (nextCursor) => postRepo.getPosts(nextCursor),
  following: (nextCursor) => postRepo.getPostsFromUsersThatUserFollows(nextCursor)
};

const getPostsOfGivenType = async () => {
  if (nextCursor.value) {
    const response = await serviceByPost[postsType.value](nextCursor.value);
    const loadedPosts = response.posts;
    posts.value.push(...loadedPosts);
    nextCursor.value = response.nextCursor;
    numberOfPostServiceCalledTimes++;
  } else {
    ({ posts: posts.value, nextCursor: nextCursor.value } = await serviceByPost[postsType.value](
      nextCursor.value
    ));
  }
};
onMounted(async () => {
  // radi subscribe na sobu
  getSocket()?.emit('subscribe', 'main_wall');

  // Slusa event za novi post
  getSocket()?.on('new_post', (message) => {
    const newPost = JSON.parse(message) as Post;
    console.log('Received new_post message:', newPost);
    if (newPost.userId !== userId) {
      newPostsCount.value += 1;
    }
  });

  getSocket()?.on('delete_post', (message) => {
    const post = JSON.parse(message) as Post;
    console.log('Received delete_post message:', post);
    const postIndex = postsIndexMap.value[post.id];
    if (postIndex > -1) {
      posts.value.splice(postIndex, 1);
    }
  });

  getSocket()?.on('new_reaction', (message) => {
    const postReaction = JSON.parse(message) as PostReactionSocket;
    console.log('Received new_reaction message:', postReaction);
    const postIndex = postsIndexMap.value[postReaction.postId];
    if (postIndex > -1 && postReaction.userId != userId) {
      posts.value[postIndex].positiveReaction = postReaction.positiveReaction;
      posts.value[postIndex].negativeReaction = postReaction.negativeReaction;
    }
  });

  if (route.query.postId) {
    dialogStore.createPostDialog(parseInt(route.query.postId as string));
  }

  loadHideChallengeBanner();
  if (storageHideChallengeBanner.value) {
    hideChallengeBanner.value = storageHideChallengeBanner.value;
  }
  await getPinnedPost();

  loadHidePinnedPostValue();
  if (hidePinnedPostRecord.value && pinnedPost.value) {
    hidePinnedPost.value = hidePinnedPostRecord.value[pinnedPost.value.id];
  }
  loadHideFollowSuggestions();
  if (storageHideFollowSuggestions.value) {
    hideFollowRecommendations.value = storageHideFollowSuggestions.value;
  }
  postsType.value = (route.query.type as PostType) || 'all';
  await getPostsOfGivenType();
  scrollToSavedPosition(route.name as string, getPostsOfGivenType);
  isContentLoaded.value = true;
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          getPostsOfGivenType();
        }
      });
    },
    {
      threshold: 0.5
    }
  );

  if (observerPoint.value) {
    observer.observe(observerPoint.value as any);
  }
});

onBeforeUnmount(() => {
  commonStore.setScrollPosition(
    route.name as string,
    window.scrollY,
    numberOfPostServiceCalledTimes
  );
  getSocket()?.off('new_post');
  getSocket()?.off('new_reaction');
});

onUnmounted(() => {
  if (observer && observerPoint.value) {
    observer.unobserve(observerPoint.value as Element);
    observer.disconnect();
  }
});

const handlePostDelete = (index: number) => {
  posts.value.splice(index, 1);
};
const handlePostUpdate = (updatedPost: Post, index: number) => {
  posts.value[index] = updatedPost;
};
const reloadPosts = async () => {
  if (divToScrollTo.value) {
    postsType.value = 'all';
    nextCursor.value = '';
    scrollToTop(divToScrollTo.value, async () => {
      newPostsCount.value = 0;
      posts.value = [];
    });
  }
};
const getPinnedPost = async () => {
  try {
    const response = await postRepo.getPinnedPost();
    pinnedPost.value = response;
  } catch (err) {
    console.log(err);
  }
};
watch(
  () => commonStore.shouldReloadPosts,
  async (newValue) => {
    if (newValue) {
      await reloadPosts();
      commonStore.setShouldReloadPosts(false);
    }
  }
);
watch(
  () => postsType.value,
  async () => {
    nextCursor.value = '';
    await getPostsOfGivenType();
  }
);
watch(
  () => route.query.type,
  (newValue) => {
    const extractedValue = (newValue as PostType) || 'all';
    postsType.value = extractedValue;
  }
);

useHead({
  meta: [
    {
      name: 'description',
      content: t('meta.indexDescription')
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      property: 'og:title',
      content: 'Underdogz.com'
    },
    {
      property: 'og:description',
      content: 'Underdogz, najposećeniji sajt za ljubitelje sporta i klađenja!'
    },
    {
      property: 'og:image',
      content: logoImage
    },
    {
      name: 'keywords',
      content: t('meta.keywords')
    }
  ]
});
//neka za za svaki slucaj
// const handlePostAdded = (post: Post) => {
//   if (post.type == 'with_video') {
//     dialogStore.createToasterNotification(
//       'success',
//       `${t('notification')}`,
//       'Uspešno ste upload-ovali video!'
//     );
//   } else {
//     posts.value.unshift(post);
//   }
// };
</script>
