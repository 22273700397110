<template>
  <NuxtLink :to="localePath(`/post/${props.pinnedPost.id}`)">
    <div class="flex items-center gap-1 py-3 px-2 rounded-full bg-neutral_500 mb-3">
      <UserPicture
        class="shrink-0"
        :user="props.pinnedPost.user"
        :display-username="false"
        size="md"
      />
      <div class="w-px h-12 bg-primary_850"></div>
      <div class="grow flex flex-col gap-1 pr-3 sm:pr-4">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <p class="text-[14px] font-bold leading-[150%] text-primary_500">
              {{ props.pinnedPost.user.username }}
            </p>
            <div class="w-px bg-primary_800 h-[21px]"></div>
            <p class="text-[12px] text-neutral_200 font-medium">
              {{ formatDateToDayMonthFormat(props.pinnedPost.created_at) }}
            </p>
          </div>
          <ClientOnly>
            <ion-icon
              @click="handleCloseClick"
              class="text-[20px] text-base_white cursor-pointer"
              name="close-outline"
            ></ion-icon>
          </ClientOnly>
        </div>
        <p class="truncate-ellipsis text-neutral_50 font-medium text-[12px]">
          {{ props.pinnedPost.postDescription }}
        </p>
      </div>
    </div>
  </NuxtLink>
</template>
<script lang="ts" setup>
import type { Post } from '~/interfaces';

const emits = defineEmits(['close-post-for-now']);

const props = defineProps({
  pinnedPost: {
    type: Object as PropType<Post>,
    required: true
  }
});

const { formatDateToDayMonthFormat } = useTimeFunctions();
const localePath = useLocalePath();
const { saveValue: hidePinnedPost, clearValue: clearHiddenPostsStorage } =
  useTmpLocalStorage<Record<number, boolean>>('hidePinnedPosts');

const handleCloseClick = (e: Event) => {
  e.preventDefault();
  clearHiddenPostsStorage();
  hidePinnedPost({
    [props.pinnedPost.id]: true
  });
  emits('close-post-for-now');
};
</script>
<style scoped>
.truncate-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
</style>
