<template>
  <button
    type="button"
    class="flex items-center justify-center px-3 py-2 border-[1.5px] border-primary_500 bg-neutral_950"
    :class="sizeClasses[props.size].button"
  >
    <ClientOnly>
      <ion-icon
        class="text-primary_500"
        :class="sizeClasses[props.size].icon"
        :name="props.icon"
      />
    </ClientOnly>
  </button>
</template>
<script setup lang="ts">
type Size = 'sm' | 'md';
const props = defineProps({
  size: {
    type: String as PropType<Size>,
    default: 'sm'
  },
  icon: {
    type: String,
    required: true
  }
});
const sizeClasses = {
  sm: {
    icon: 'text-[24px]',
    button: 'h-[48px] w-[48px] rounded-[48px]'
  },
  md: {
    icon: 'text-[32px]',
    button: 'h-[56px] w-[56px] rounded-[56px]'
  }
};
</script>
