import { useCommonStore } from '~/store/commonStore';

export function useScrollToSavedPosition() {
  const commonStore = useCommonStore();
  async function scrollToSavedPosition(routeName: string, functionToPrepareContent: Function) {
    if (commonStore.scrollPositions[routeName]) {
      const serviceCallsNumber = commonStore.scrollPositions[routeName].numberOfServiceCalls;
      if (
        serviceCallsNumber &&
        serviceCallsNumber < 10 &&
        commonStore.scrollPositions[routeName].positionNumber
      ) {
        for (let i = 0; i < serviceCallsNumber; i++) {
          await functionToPrepareContent();
        }
      }
      window.scrollTo({
        top: commonStore.scrollPositions[routeName].positionNumber,
        behavior: 'smooth'
      });
    }
  }
  return {
    scrollToSavedPosition
  };
}
