<template>
  <div class="flex items-center justify-between py-1">
    <UserPicture color="text-neutral_50" :user="props.user" />
    <ButtonComponent
      @click="handleFollowing"
      type="text"
      :text="isFollowing ? $t('unfollow') : $t('follow')"
    />
  </div>
</template>
<script lang="ts" setup>
import type { User } from '~/interfaces';
import { userRepository } from '~/repositories/userRepository';

const { $api } = useNuxtApp();
const userRepo = userRepository($api);
const props = defineProps({
  user: {
    type: Object as PropType<User>,
    required: true
  }
});
const isFollowing = ref(true);
onMounted(() => {
  isFollowing.value = props.user.followingBack != undefined ? props.user.followingBack : true;
});
watch(
  () => props.user.followingBack,
  (newValue) => {
    isFollowing.value = newValue != undefined ? newValue : true;
  }
);

const handleFollowing = async () => {
  await userRepo.handleUserFollowing(isFollowing.value, props.user.id);
  isFollowing.value = !isFollowing.value;
};
</script>
